import './AppFooter.css';
import FavoriteIcon from '@mui/icons-material/Favorite';

function AppFooter() {
  return (
    <footer className="app-footer">
      <div>Copyright &copy; 2023 Simple Browser.</div>
      <div>Contact Us: <a href='mailto:contact@getsimplebrowser.com'>contact@getsimplebrowser.com</a></div>
      <div>Made with <FavoriteIcon className='heart' sx={{ color: 'red' }}/> in Madhubani</div>
    </footer>
  );
}

export default AppFooter;
