import Button from '@mui/joy/Button';
import './Downloads.css';
import Typography from '@mui/joy/Typography';

function Downloads() {
  return (
    <div className='downloads'>
        <Typography level="h1" component="h2">
            Download
        </Typography>
        <div className="download-buttons">
            <Button aria-label="Windows" component="a" href='https://www.dropbox.com/s/p586pedb2wy01lz/windows-package.zip?dl=1'>Windows </Button>
            <Button aria-label="Mac" component="a" href='https://www.dropbox.com/s/96mtga8zixd7ji4/mac-package.zip?dl=1'>Mac </Button>
            <Button aria-label="Linux" component="a" href='https://www.dropbox.com/s/w52g99469nddide/linux-package.zip?dl=1'>Linux</Button>
        </div>
    </div>
  );
}

export default Downloads;