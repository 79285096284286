import React, {Component} from 'react';
import Typed from 'typed.js';
import './TypedLetters.css';

export default class TypedLetters extends Component {
    componentDidMount() {
        // You can pass other options here, such as typing speed, back speed, etc.
        const options = {
            strings: ['Fast', 'Secure', 'Ad Free', 'Blocks Trackers'],
            typeSpeed: 50,
            backSpeed: 50,
            shuffle: true,
            loop: true
        };
        // this.el refers to the <span> in the render() method
        this.typed = new Typed(this.el, options);
    }

    componentWillUnmount() {
        // Make sure to destroy Typed instance on unmounting
        // to prevent memory leaks
        this.typed.destroy();
    }

    render() {
        return (
            <div className="typed-letters">
            <div className="wrap">
                <div className="type-wrap">
          <span
              style={{ whiteSpace: 'pre' }}
              ref={(el) => { this.el = el; }}
          />
                </div>
            </div>
            </div>
        );
    }
}
