import ModeToggle from '../ModeToggle/ModeToggle';
import './AppHeader.css';
import Typography from '@mui/joy/Typography';
import Avatar from '@mui/joy/Avatar';

function AppHeader() {
  return (
    <header className="App-header">
      <div className='left'>
        <Avatar src="/64x64.png" srcSet="/64x64.png" sx={{ borderRadius: 'sm' }}/>
        <Typography level="h4">Simple</Typography>
      </div>

      <ModeToggle/>
    </header>
  );
}

export default AppHeader;