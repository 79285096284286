import React, {Component} from 'react';
import './App.css';
import { CssVarsProvider } from '@mui/joy/styles';
import AppHeader from './components/AppHeader/AppHeader';
import Downloads from './components/Downloads/Downloads';
import AppFooter from './components/AppFooter/AppFooter';
import TypedLetters from './components/TypedLetters/TypedLetters';

export default class App extends Component{
render(){
    return (
        <CssVarsProvider>
            <div className="App">
                <AppHeader/>
                <TypedLetters/>
                <Downloads/>
                <AppFooter/>
            </div>
        </CssVarsProvider>
    );
}
}
